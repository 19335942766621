.search {
  padding: 92px 0 50px;
  box-sizing: border-box;
}

.search__content {
  width: calc(100% - 28px);
  max-width: 1280px;
  margin: 0 auto;
}

.search__form {
  display: flex;
  gap: 14px;
  padding-bottom: 9px;
  border-bottom: 1px solid var(--grey-dark);
}

.search__input {
  border: none;
  width: calc(100% - 48px);
}

.search__input::placeholder {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey-text);
}

.search__submit-btn {
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  background-color: transparent;
  background-image: url(../../images/ico-find.svg);
  border: none;
  border-radius: 50%;
  transition: opacity .3s linear;
  cursor: pointer;
}

.search__submit-btn:hover {
  opacity: 0.8;
}

.search__submit-btn_disabled {
  filter: grayscale(100%);
  opacity: 0.3;
  cursor: wait;
}

.search__submit-btn_disabled:hover {
  filter: grayscale(100%);
  opacity: 0.3;
  cursor: wait;
}

@media screen and (min-width: 768px) {
  .search__content {
    width: calc(100% - 60px);
  }

  .search__input {
    padding-left: 48px;
    background-color: transparent;
    background-image: url(../../images/ico-search.svg);
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: left center;
  }

  .search__input::placeholder {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .search {
    padding: 86px 0 61px;
  }

  .search__content {
    width: calc(100% - 60px);
  }
}

@media screen and (min-width: 1280px) {
  .search {
    padding: 70px 0 48px;
  }

  .search__content {
    width: calc(100% - 140px);
  }
}
