.navigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  z-index: 1;
  transition: all .5s linear;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.navigation__content {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 520px;
  height: 100vh;
  padding: 144px 10px 46px;
  background-color: var(--white);
  box-sizing: border-box;
  transition: all .5s linear;
}

.navigation_opened {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
}

.navigation__content,
.navigation__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.navigation__link {
  margin: 0;
  padding-bottom: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--black);
  text-decoration: none;
  transition: opacity .3s linear;
}

.navigation__link_theme_dark {
  color: var(--white);
}

.navigation__link_active {
  border-bottom: 2px solid var(--black);
}

.navigation__link_invisible {
  display: none;
}

.navigation__profile-link {
  position: relative;
  margin: auto 0 0;
  padding: 8px 13px 8px 31px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  background-color: transparent;
  background-image: url(../../images/ico-profile.svg);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 12px 14px;
  text-decoration: none;
  border: 2px solid rgba(170, 170, 170, 0.2);
  border-radius: 50px;
  transition: opacity .3s linear;
}

.navigation__profile-link_theme_dark {
  color: var(--white);
  background-image: url(../../images/ico-profile_white.svg);
}

.navigation__link:hover,
.navigation__profile-link:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .navigation__content {
    padding: 159px 90px 90px;
  }
}

@media screen and (min-width: 1280px) {
  .navigation {
    position: static;
    background-color: transparent;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;

    pointer-events: all;
    visibility: visible;
    opacity: 1;
  }

  .navigation__content {
    position: static;
    width: calc(50% + 126px);
    max-width: 100%;
    height: auto;
    padding: 0;
  }

  .navigation__content,
  .navigation__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
  }

  .navigation__content {
    justify-content: space-between;
    gap: 16px;
  }

  .navigation__wrapper {
    gap: 16px;
  }

  .navigation__link {
    padding-bottom: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  .navigation__link_type_main {
    display: none;
  }
}
