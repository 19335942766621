.profile {
  height: calc(100vh - 74px);
  padding: 70px 30px 40px;
  color: var(--black);
  box-sizing: border-box;
}

.profile__title {
  max-width: 396px;
  margin: 0 auto 80px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.profile__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  padding: 4px 0;
}

.profile__line:not(:first-of-type) {
  border-top: 1px solid var(--grey-dark);
}

.profile__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 396px;
  height: calc(100% - 105px);
  margin: 0 auto;
}

.profile__text {
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  color: var(--black);
  border: none;
  background-color: transparent;
  padding: 12px 0;
}

.profile__text_weight_smaller {
  flex-grow: 1;
  max-width: 85%;
  font-weight: 400;
}

.profile__edit-button {
  display: inline-flex;
  align-self: center;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background: transparent;
  border: none;
  transition: opacity .3s linear;
  cursor: pointer;
}

.profile__edit-button:hover {
  opacity: 0.8;
}

.profile__exit-button {
  display: inline-flex;
  align-self: center;
  margin: 0;
  padding: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background: transparent;
  border: none;
  color: var(--accent);
  transition: opacity .3s linear;
  cursor: pointer;
}

.profile__exit-button:hover {
  opacity: 0.8;
}

.profile__submit-btn {
  display: block;
  width: 100%;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: var(--white);
  background: var(--secondary);
  border: none;
  border-radius: 3px;
  transition: opacity .3s linear;
  cursor: pointer;
}

.profile__submit-btn:hover {
  opacity: 0.8;
}

.profile__submit-btn:disabled {
  color: var(--grey-light-text);
  background: var(--grey-dark);
  cursor: auto;
}

.profile__error {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 26px;
  margin: auto 0 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: var(--accent);
  opacity: 1;
}

.profile__error_visible {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .profile {
    padding: 236px 30px 269px;
  }

  .profile__wrapper {
    height: calc(100% - 100px);
  }

  .profile__edit-button {
    font-size: 13px;
    line-height: 16px;
  }

  .profile__link {
    font-size: 13px;
    line-height: 16px;
  }
}

@media screen and (min-width: 1280px) {
  .profile {
    padding: 74px 30px 70px;
  }
}
