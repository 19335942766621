.footer {
  margin-top: auto;
  padding: 79px 0 10px;
  background: var(--white);
  box-sizing: border-box;
}

.footer__content {
  max-width: 1280px;
  width: calc(100% - 20px);
  margin: 0 auto;
}

.footer__text {
  margin: 0 0 21px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: var(--grey-text);
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 30px;
  border-top: 1px solid var(--grey-dark);
}

.footer__link {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: var(--black);
  transition: opacity .3s linear;
}

.footer__link:hover {
  opacity: 0.7;
}

.footer__copyright {
  margin: 18px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey-text);
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 79px 0 20px;
  }

  .footer__content {
    width: calc(100% - 60px);
  }

  .footer__text,
  .footer__link {
    font-size: 13px;
    line-height: 16px;
  }

  .footer__wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
    padding-top: 20px;
  }

  .footer__copyright {
    margin: 0 auto 0 0;
    font-size: 13px;
    line-height: 16px;
    color: var(--black);
  }
}

@media screen and (min-width: 1280px) {
  .footer__content {
    width: calc(100% - 140px);
  }
}
