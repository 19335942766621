.about-project {
  padding: 70px 0;
  background-color: var(--white);
  color: var(--black);
}

.about-project__content {
  max-width: 1280px;
  width: calc(100% - 36px);
  margin: 0 auto;
}

.section-title {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--black);
}

.section-title,
.about-project__subtitle {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.04em;
}

.about-project__description {
  margin: 60px 0 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
}

.about-project__subtitle {
  margin: 0 0 16px;
}

.about-project__text {
  margin: 0 0 56px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.about-project__table {
  display: grid;
  grid-template-columns: 100px 1fr;
  margin-top: 4px;
}

.about-project__table-text {
  margin: 0;
  padding: 11px 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}

.about-project__back {
  background-color: var(--primary);
}

.about-project__front {
  background-color: var(--grey-light);
}

.about-project__caption {
  padding: 10px 10px 0;
  color: var(--grey-text);
  background-color: var(--white);
}

@media screen and (min-width: 768px) {
  .about-project {
    padding: 90px 0;
  }

  .about-project__content {
    width: calc(100% - 100px);
  }

  .section-title {
    padding-bottom: 23px;
    font-size: 22px;
    line-height: 27px;
  }

  .about-project__description {
    margin: 70px 0 0;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }

  .about-project__subtitle {
    margin: 0 0 22px;
    font-size: 20px;
    line-height: 24px;
  }

  .about-project__text {
    font-size: 12px;
    line-height: 18px;
  }

  .about-project__table {
    grid-template-columns: 140px 1fr;
    margin-top: 37px;
  }

  .about-project__table-text {
    padding: 9px 10px;
    font-size: 14px;
    line-height: 17px;
  }

  .about-project__caption {
    padding: 14px 10px 0;
  }
}

@media screen and (min-width: 1280px) {
  .about-project {
    padding: 110px 0;
  }

  .about-project__content {
    width: calc(100% - 140px);
  }

  .about-project__subtitle {
    margin: 0 0 26px;
  }

  .about-project__text {
    font-size: 14px;
    line-height: 20px;
  }

  .about-project__table {
    grid-template-columns: 228px 1fr;
    margin-top: 54px;
  }
}
