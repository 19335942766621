.portfolio {
  margin-top: 70px;
  background-color: var(--white);
}

.portfolio__title {
  margin: 0 0 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-text);
}

.portfolio__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.portfolio__category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 20px 0 19px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.04em;
  text-decoration: none;
  color: var(--black);
  border-bottom: 1px solid var(--grey-dark);
  transition: opacity .3s linear;
}

.portfolio__category:hover {
  opacity: 0.7;
}

.portfolio__arrow {
  transition: all .3s linear;
}

.portfolio__category:hover .portfolio__arrow {
  transform: scale(1.3);
}

@media screen and (min-width: 768px) {
  .portfolio {
    margin-top: 90px;
  }

  .portfolio__title {
    margin: 0 0 50px;
    font-size: 18px;
    line-height: 20px;
  }

  .portfolio__category {
    align-items: start;
    padding: 20px 0 9px;
    font-size: 28px;
    line-height: 50px;
  }

  .portfolio__arrow {
    line-height: 60px;
  }
}

@media screen and (min-width: 1280px) {
  .portfolio {
    margin-top: 100px;
  }

  .portfolio__category {
    padding: 20px 0 9px;
    font-size: 30px;
  }
}
