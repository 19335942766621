.not-found {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  color: var(--black);
}

.not-found__title {
  margin: 0;
  font-size: 80px;
  line-height: 97px;
}

.not-found__description {
  margin: 10px 0 0;
  font-size: 12px;
  line-height: 15px;
}

.not-found__link {
  position: absolute;
  bottom: 30px;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: opacity .3s linear;
}

.home-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: var(--secondary);
}

.home-link:hover,
.not-found__link:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .not-found__wrapper {
    padding-bottom: 15px;
  }

  .not-found__title {
    font-size: 140px;
    line-height: 169px;
  }

  .not-found__description {
    font-size: 16px;
    line-height: 19px;
  }

  .not-found__link {
    bottom: 222px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (min-width: 1280px) {
  .not-found__link {
    bottom: 60px;
  }
}
