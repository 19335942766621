.checkbox {
  width: 100%;
  margin-top: 43px;
  text-align: center;
}

.checkbox__content {
  position: relative;
  display: inline-block;
}

.checkbox__input {
  display: none;
}

.checkbox__slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  height: 20px;
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(50, 50, 71, 0.08), 0 4px 8px rgba(50, 50, 71, 0.06);
  border-radius: 16px;
  transition: 0.3s;
  cursor: pointer;
}

.checkbox__slider:before {
  position: absolute;
  content: '';
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: var(--grey-bg);
  border-radius: 50%;
  transition: 0.3s;
}

.checkbox__name {
  margin-left: 49px;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  cursor: pointer;
}

.checkbox__input:checked + .checkbox__slider:before {
  background-color: var(--primary);
  transform: translateX(16px);
}

@media screen and (min-width: 768px) {
  .checkbox {
    margin-top: 30px;
  }

  .checkbox__name {
    font-size: 14px;
    line-height: 18px;
  }
}
