.popup {
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  transition: visibility .3s, opacity .3s linear;
}

.popup_opened {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}

.popup__wrapper {
  position: relative;
  width: calc(100% - 38px);
  max-width: 430px;
  padding: 60px 36px;
  background-color: var(--white);
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  border-radius: 10px;
  box-sizing: border-box;
}

.popup__button-close {
  position: absolute;
  top: -36px;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(../../images/icon-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
  transition: opacity .3s linear;
  cursor: pointer;
}

.popup__button-close:hover {
  opacity: .6;
}

.popup__image {
  display: block;
  min-width: 282px;
  max-width: 75vw;
  max-height: 75vh;
  object-fit: cover;
}

.popup__image_place_info-tooltip {
  width: 120px;
  height: 120px;
  margin: 0 auto 32px;
  min-width: 120px;
}

.popup__caption {
  padding: 10px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);
}

.popup__caption_place_info-tooltip {
  padding: 0;
  margin: 0;
  color: var(--black);
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  max-width: 358px;
}

@media (min-width: 512px) {
  .popup__button-close {
    width: 32px;
    height: 32px;
    background-size: 32px;
    top: -40px;
    right: -40px;
  }
}
